import React from 'react';
import {ReactTyped} from "react-typed";

// import { AiTwotoneMail } from "react-icons/ai"
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
// import Modal from '../elements/Modal';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  state = {
    videoModalActive: false,
    scrollTop : 0,
    // screenHeight : window.document.body.offsetHeight,
  }  
  

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }  


  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      targetRef,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const handleClick = () => {
      // const zoom = window.devicePixelRatio;
      // const scrolltopix = window.document.body.offsetHeight /4.7;
      // window.scrollTo({top:  scrolltopix,behavior: "smooth"});

      this.props.targetRef.current.scrollIntoView({ behavior: "smooth" });
   };
   const handleEmailClick = () => {
    window.location = 'mailto:contact@arnoldfreitas.com'
    // window.location = 'mailto:arnold.sfreitas@gmail.com'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                  <h1 className="mt-0 mb-16">
                   Arnold Freitas
                  </h1>
                    <p>
                      {" "}
                      <ReactTyped
                        strings={["Software Engineer.", 
                          "Data Scientist.", 
                          "AI Engineer.",
                          "Expertise in Machine Learning.",
                        ]}
                        typeSpeed={100}
                        loop
                        backSpeed={20}
                        showCursor={true}
                      />
                    </p>
                  <p className="mt-0 mb-32">
                  Do you want to drive innovation through harnessing the power of artificial intelligence? Let me help you to turn innovative data science concepts into practical, impactful solutions.
                  </p>
                  <ButtonGroup>
                    <Button tag="a" color="primary" onClick={handleClick} wideMobile>
                     Portfolio
                    </Button>
                    <Button tag="a" color="dark" onClick={handleEmailClick} wideMobile>
                     Contact me
                      {/* <p> <p className="icon"><AiTwotoneMail size="1rem"/></p>Email me</p> */}
                    </Button>
                  </ButtonGroup>
                </div>
                {/* <div className="features-tiles-item-image mb-16"> */}
                <div className="hero-figure split-item-image split-item-image-fill illustration-element-01 reveal-from-bottom">
                  <Image
                      src={require('../../assets/images/CV-Image2.png')}
                      alt="myImage"
                      width={396}
                      height={396} />
                  {/* <a
                    // data-video="https://player.vimeo.com/video/174002812"
                    // href="#0"
                    // aria-controls="video-modal"
                    // onClick={this.openVideoModal}
                  >
                    <Image
                      src={require('../../assets/images/CV-Image.jpeg')}
                      alt="myImage"
                      width={528}
                      height={396} />
                  </a> */}
                </div>
                {/* <Modal
                  id="video-modal"
                  show={this.state.videoModalActive}
                  handleClose={this.closeVideoModal}
                  video="https://player.vimeo.com/video/174002812"
                  videoTag="iframe" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;