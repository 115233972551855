import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

import Carousel from '../elements/Carousel';
import CarouselItem from '../elements/CarouselItem';
// import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class News extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pushRight,
      innerRef,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Representable Projects: Turning Ideas Into Impact',
      paragraph: 'Projects where creativity meets expertise, exemplary for what we can achieve together'
    };

      // TODO: Add the company/location for each project
      // TODO: Set section length by the largest text
    const sectionContent = [
      {
        title : "Cloud-Based Content Allocation and Social Media Optimization Pipeline ",
        paragraph : "In this project, I developed and optimized content allocation pipelines by moving localized processes to the cloud, allowing for greater scalability and efficiency. The system runs on data from TikTok, Instagram, and internal resources like AirTable through API integrations. I implemented scripts to scrape and analyze this data, focusing on identifying high-engagement content and determining which accounts would maximize post performance. Additionally, I automated the content scheduling process by creating a calendar that assigns optimized content to specific accounts, ensuring timely and strategic publishing.",
      },
      {
        title : "Magnetic Sensor Calibration",
        paragraph : "This project focused on calibrating magnetic sensors for satellites using physics-informed neural networks. By leveraging telemetry data, we created a model to denoise magnetic field measurements, eliminating artificial disturbances and reducing measurement errors. Our approach improved the accuracy of satellite magnetic field data, which is crucial for various scientific and navigational applications. This project demonstrates our capability to integrate advanced neural networks with physical principles to solve complex problems.",
      },
      {
        title : "Integration to LLM and Voice Models for Mobile Robot",
        paragraph : "In this innovative project, we integrated pre-trained Large Language Models (LLMs) into a mobile robot, enabling it to access a database and deliver presentations and conversations about company projects and processes. The robot was equipped with Speech-to-Text and Text-to-Speech tools, allowing it to engage in full conversations. This integration empowered the robot to serve as an interactive guide and knowledge resource, showcasing our skills in natural language processing, robotics, and AI-driven communication.",
      },
      {
        title : "Integration to LLM for Internal Company Chatbot",
        paragraph : "We enhanced the capabilities of a company's internal chatbot by integrating pre-trained Large Language Models (LLMs). The chatbot was designed to access a database and provide detailed presentations and conversations about the company's projects and processes. This integration allowed the chatbot to become a valuable tool for internal communication and knowledge sharing, reflecting our expertise in deploying advanced AI solutions for enterprise applications.",
      },
      {
        title : "Ranking System for Technical Proposals",
        paragraph : "This project involved implementing a Natural Language Processing (NLP) model to evaluate and rank technical proposals for an engineering consulting company. By analyzing past projects and their outcomes, the model was able to assess the likelihood of success for new proposals. We employed transfer learning techniques to fine-tune the NLP model and used web-scraping to gather relevant data. This solution streamlined the proposal evaluation process, demonstrating our ability to apply NLP and machine learning to real-world business challenges,"
      },
      {
        title : "Bitcoin Trader",
        paragraph : "Our Bitcoin Trader project involved creating an intelligent agent capable of managing a Bitcoin wallet by making informed buy and sell decisions based on market conditions. We trained a Long Short-Term Memory (LSTM) network to predict future Bitcoin prices and employed a Multi-Layer Perceptron (MLP) to analyze multiple financial indices for decision-making. The trading environment was developed using open APIs to fetch real-time prices and indices, ensuring the agent had access to the most current market data. Implemented in Python, this project highlights our proficiency in financial modeling, machine learning, and real-time data integration.",
      },
      // {
      //   title : "",
      //   paragraph : "",
      // },
    ];
  
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container" ref={this.props.innerRef}>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>
            <Carousel autorotate={true}>
              { sectionContent.map((item, i) => 
              <CarouselItem key={i} >
                <div className="carousel-inner reveal-from-bottom">
                  <div className="tiles-item-inner has-shadow">
                    <div className="news-item-content">
                      <div className="news-item-body">
                        <h3 className="news-item-title h4 mt-0 mb-8">
                          { item.title }
                        </h3>
                        <p className="mb-16 text-sm">
                          { item.paragraph }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CarouselItem>
              ) }
            </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default forwardRef((props, ref) => <News 
    innerRef={ref} {...props}
/>); ;