import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16"><a target="_blank" rel="noreferrer"  href="https://icons8.com/icon/111460/graduation-cap">
                      <Image
                        src={require('../../assets/images/icons8-graduation-cap-64.png')}
                        alt="Features tile icon 01"
                        width={64}
                        height={64} />
                      </a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Excellence
                    </h4>
                    <p className="m-0 text-sm">
                     I am dedicated to delivering high-quality, reliable, and maintainable software solutions. My commitment to excellence ensures that every project meets the highest standards, providing lasting value to all clients.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    <a target="_blank" rel="noreferrer"  href="https://icons8.com/icon/NXD738PNF5MC/contribution">
                      <Image
                        src={require('../../assets/images/icons8-contribution-64.png')}
                        alt="Features tile icon 02"
                        width={64}
                        height={64} />
                        </a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Contributing Force
                    </h4>
                    <p className="m-0 text-sm">
                    I make impactful contributions to the technological landscape. Solutions will not only solve immediate challenges but also have long-term value and a positive effect on your organization.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    <a target="_blank" rel="noreferrer"  href="https://icons8.com/icon/Cc5U1vG2huUW/weight-care">
                      <Image
                        src={require('../../assets/images/icons8-integrity-50.png')}
                        alt="Features tile icon 03"
                        width={64}
                        height={64} />
                        </a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Integrity
                    </h4>
                    <p className="m-0 text-sm">
                    I highly value transparency, ethical practices, and trustworthiness in all dealings. Our collaboration builds on mutual trust and confidence. I continue to support all clients even after the project has been completed, fostering lasting partnerships built on trust and mutual success.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    <a target="_blank" rel="noreferrer"  href="https://icons8.com/icon/42816/idea">
                      <Image
                        src={require('../../assets/images/icons8-idea-64.png')}
                        alt="Features tile icon 04"
                        width={64}
                        height={64} />
                        </a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Full-stack Development
                    </h4>
                    <p className="m-0 text-sm">
                    I transform data science and machine learning prototypes into robust software products. These solutions will drive business value and innovation. By implementing software tailored to the unique needs of the clients I can provide a lasting and positive effect on their organization.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    <a target="_blank" rel="noreferrer"  href="https://icons8.com/icon/61864/artificial-intelligence">
                      <Image
                        src={require('../../assets/images/icons8-ai-64.png')}
                        alt="Features tile icon 05"
                        width={64}
                        height={64} />
                        </a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      AI Development
                    </h4>
                    <p className="m-0 text-sm">
                    I implement and integrate advanced machine learning algorithms and AI models into client systems. I help you unlock new opportunities for automation, optimization, and decision-making. All solutions are end-to-end, from data preprocessing to model deployment and monitoring, ensuring that AI solutions deliver impact and value.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="500">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                    <a target="_blank" rel="noreferrer" href="https://icons8.com/icon/gR7LJ77L9EAn/flexibility">
                      <Image
                        src={require('../../assets/images/icons8-flexibility-64.png')}
                        alt="Features tile icon 06"
                        width={64}
                        height={64} /></a>
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Flexibility
                    </h4>
                    <p className="m-0 text-sm">
                    I understand that things can change quickly and unexpectedly. I am always ready to adapt to new circumstances and priorities, ensuring that your evolving needs are met and the projects deliver successful outcomes. Communication is key.
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;