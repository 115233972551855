import React, { createRef } from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
// import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.resultRef = createRef()  
  }

  render() {
    return (
      <React.Fragment>
        <HeroSplit hasBgColor invertColor targetRef={this.resultRef}  />
        <FeaturesTiles />
        {/* <FeaturesTabs topDivider bottomOuterDivider /> */}
        <News className="illustration-section-01" ref={ this.resultRef } />
        <Roadmap topOuterDivider />
        {/* <Pricing hasBgColor pricingSlider /> */}
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default Home;