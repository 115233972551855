import React from 'react';
import classNames from 'classnames';
import { AiFillGithub } from "react-icons/ai"
import { AiFillLinkedin } from "react-icons/ai"

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
      style={{ maxHeight: '10px' }} 
    >
      <ul className="list-reset">
      <li>
          <a href="https://github.com/arnoldfreitas"
            target="_blank"
            rel="noreferrer">
                <h3 className="icon"><AiFillGithub size="2rem"/></h3>
            </a>
        </li>
        <li>
        <a href="https://www.linkedin.com/in/arnold-freitas-a2652b70/"
            target="_blank"
            rel="noreferrer">
                <h3 className="icon"><AiFillLinkedin size="2rem"/></h3>
            </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;