import React from 'react';
// import { Link } from "react-router-dom";
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );


    // const headerButton = () => {
    //   return (
    //     <>Would you like to discuss the possibility of working together? Please 
    //     <Link
    //         to='#'
    //         onClick={(e) => {
    //             window.location = 'mailto:contact@arnoldfreitas.com';
    //             // e.preventDefault();
    //         }}
    //     >
    //         send me an e-mail
    //     </Link>  and we will schedule a talk. Roughly, our project will follow the roadmap below.</>
    //   )};


    const sectionHeader = {
      title: 'Roadmap',
      paragraph: "Would you like to discuss the possibility of working together? Please send me an e-mail and we will schedule a talk. Roughly, our project will follow the roadmap below."
    };

    const sectionContent = [
    {
      title: 'Exploring Possibilities',
      paragraph: 'Connect with me to discuss your project ideas, goals, and challenges. Together, we\'ll assess the potential impact and scope of your vision'
    },
    {
      title: 'Defining the Blueprint',
      paragraph: 'We dive deeper into your project\'s requirements, aligning data science approaches with your objectives. A strategic roadmap is crafted to ensure practical, impactful solutions'
    },
    {
      title: 'Bringing Ideas to Life',
      paragraph: 'Design a tailored solution, building tools that address your needs, with a focus on feasibility and user experience'
    },
    {
      title: 'Turning Concepts into Reality',
      paragraph: 'Develop and deploy your solution, rigorously testing it to ensure performance, accuracy, and stability, refining as needed for optimal results'
    },
    {
      title: 'Value Delivery & Continuous Support',
      paragraph: 'Your solution goes live, ready to drive value and insights for your organization. We provide ongoing support, tracking performance and ensuring sustained impact'
    },
    // {
    //   title: '',
    //   paragraph: ''
    // },
    ];

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              {sectionContent.map((item,i)=> 
               <TimelineItem key={i} title={item.title}>
                {item.paragraph}
               </TimelineItem>
              )}
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;